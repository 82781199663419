import moment from "moment";
import { createCrudActions } from '../utils/crudGenerator';

const formatOffers = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        date: item.date && moment(item.date),
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchOffers,
    create: createOffer,
    update: updateOffer,
    delete: deleteOffers
} = createCrudActions('OFFER', "Oferta", formatOffers);
