import moment from "moment";
import { createCrudActions } from "../utils/crudGenerator";
import { SHOW_NOTIFICATION, TEMPLATE_FORM_TOOGLE_LOADING } from "../constants";
import { API } from "../constants/api";
import { axios } from "../connection/ConnectionHandler";

const formatTemplates = (data) => {
  if (!data) {
    return [];
  }
  const formatItem = (item) => ({
    ...item,
    label: `${item.name}`,
    value: item._id,
    date: item.date && moment(item.date),
  });

  if (Array.isArray(data)) {
    return data.map(formatItem);
  }
  return formatItem(data);
};

export const {
  fetch: fetchTemplates,
  create: createTemplate,
  update: updateTemplate,
  delete: deleteTemplates,
} = createCrudActions("TEMPLATE", "Plantilla", formatTemplates);

export const sendTemplate = (updatedTemplate) => {
  return async (dispatch) => {
    dispatch({ type: TEMPLATE_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.templates.send}${updatedTemplate._id}`, updatedTemplate)
      .then((response) => {
        dispatch({ type: TEMPLATE_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Plantilla enviada.",
          },
        });
        return response.status;
      })
      .catch((err) => {
        dispatch({ type: TEMPLATE_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
